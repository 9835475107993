import React from 'react';
import { Modal, Button, notification } from 'antd';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';
import { RequestType } from 'constant/constant';

interface IProps {
  notiAssess: any;
  setNotiAssess: Function;
}

export default (props: IProps) => {
  const { notiAssess = {} } = props;
  const history = useHistory();
  const info = notiAssess.info?.conversation?.conversationMember || {};

  const toAssess = async () => {
    try {
      if (notiAssess?.info?.type === RequestType.VIDEOCALL) {
        await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
      }
      window.localStorage.setItem('conversationId', info?.conversationId || '');
      window.localStorage.setItem('channelName', notiAssess.info?.conversation?.channelName || '');
      window.localStorage.setItem('uid', info?.uid || '');
      window.localStorage.setItem('agoraToken', info?.agoraToken || '');
      window.location.replace(`/assess/${notiAssess.info?.id}`);
    } catch (err) {
      notification.error({
        message: i18next.t('noti.checkDevice'),
      });
    }
  };
  return notiAssess.isShow ? (
    <Modal
      title=""
      visible={notiAssess.isShow}
      footer={false}
      closable={false}
      maskClosable={false}
      afterClose={() => toAssess()}
    >
      <div className="text-center font-24">
        <div>{i18next.t('noti.backToAssess')}</div>
        <Button type="primary" htmlType="submit" className={`bg-pasblue mt-30`} onClick={toAssess}>
          {i18next.t('common.ok')}
        </Button>
      </div>
    </Modal>
  ) : (
    <></>
  );
};
