import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthWrapper from 'wrappers/AuthWrapper';
import { ConfigProvider } from 'antd';
import ja_JP from 'antd/es/locale/ja_JP';
import { useQuery } from 'react-query';
import { getResource } from 'api/resource';

const Login = lazy(() => import('pages/Login'));
const SignUp = lazy(() => import('pages/SignUp'));
const LandingPage = lazy(() => import('pages/LandingPage'));

export default function AppWrapper() {
  useQuery('resource', getResource, { enabled: true });
  window.localStorage.setItem('i18nextLng', 'ja');
  const customJaJP = { ...ja_JP, ...{ Empty: { description: 'データが見つかりませんでした' } } };
  return (
    <ConfigProvider locale={customJaJP}>
      <div className="root-wrapper">
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/sign-up" exact component={SignUp} />
          <Route path="/lp" exact component={LandingPage} />
          <Route path="/" component={AuthWrapper} />
        </Switch>
      </div>
    </ConfigProvider>
  );
}
