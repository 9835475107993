import configs from './index';

export default (onShowNoti: Function, onClickNoti: Function, profile?: any) => {
  const OneSignal = (<any>window).OneSignal || [];
  OneSignal.push(() => {
    OneSignal?.init({
      appId: configs.ONESIGNAL_APP_ID,
      safari_web_id: configs.ONESIGNAL_SAFARI_APP_ID,
      notifyButton: {
        enable: true,
      },
      notificationClickHandlerAction: 'focus',
      notificationClickHandlerMatch: 'origin',
    });
    OneSignal.sendTag('userId', profile?.data?.id);
    OneSignal.on('notificationDisplay', (event: any) => {
      onShowNoti(event);
    });
    const notificationOpenHandler = (notification: any) => {
      onClickNoti(notification);
      OneSignal?.addListenerForNotificationOpened(notificationOpenHandler); // Add listener again
    };
    OneSignal?.addListenerForNotificationOpened(notificationOpenHandler);
  });
};
