import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import styles from './styles.module.scss';
import { SideNavContext } from 'context/SideNavContext';
import {
  HomeOutlined,
  UserOutlined,
  TransactionOutlined,
  SolutionOutlined,
  ShoppingOutlined,
  SafetyOutlined,
  NotificationOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  TabletOutlined,
} from '@ant-design/icons';
import i18next from 'i18next';
import { Permission } from 'constant/constant';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

interface IProps {
  pc: Boolean;
  isOpenSideNav: Boolean;
  setOpenSideNav: Function;
}

export default function SideNav(props: IProps) {
  const { pc, isOpenSideNav, setOpenSideNav } = props;
  const { collapsed } = useContext(SideNavContext);
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState('1');
  const token = Cookies.get('token') || '';
  const info = jwt_decode(token) as any;
  const permissions = info?.permissions || [];
  const routes = [
    {
      key: 'home',
      text: i18next.t('common.dashboard'),
      url: '/',
      icon: <HomeOutlined />,
      id: '',
    },
    {
      key: 'top',
      text: i18next.t('common.topPageManage'),
      url: '/top',
      icon: <TabletOutlined />,
      id: Permission.RESOURCE_MANAGEMENT,
    },
    {
      key: 'member',
      text: i18next.t('common.userManage'),
      url: '/member',
      icon: <UserOutlined />,
      id: Permission.MEMBER_MANAGEMENT,
    },
    {
      key: 'famous',
      text: i18next.t('common.famousPeople'),
      url: '/famous',
      icon: <UsergroupAddOutlined />,
      id: Permission.FAMOUS_PEOPLE_MANAGEMENT,
    },
    {
      key: 'subcriber',
      text: i18next.t('common.registerManage'),
      url: '/subcriber',
      icon: <UserAddOutlined />,
      id: Permission.MEMBER_MANAGEMENT,
    },
    {
      key: 'product',
      text: i18next.t('common.productManage'),
      url: '/product',
      icon: <ShoppingOutlined />,
      id: Permission.PRODUCT_MANAGEMENT,
    },
    {
      key: 'transaction',
      text: i18next.t('common.transactionManage'),
      url: '/transaction',
      icon: <TransactionOutlined />,
      id: Permission.APPRAISE_MANAGEMENT,
      subId: Permission.TRANSACTION_MANAGEMENT,
    },
    {
      key: 'notify',
      text: i18next.t('common.notiManage'),
      url: '/notify',
      icon: <NotificationOutlined />,
      id: Permission.PUSH_NOTIFICATION_MANAGEMENT,
    },
    {
      key: 'user',
      text: i18next.t('common.adminManage'),
      url: '/user',
      icon: <SolutionOutlined />,
      id: Permission.USER_MANAGEMENT,
    },
    {
      key: 'permission',
      text: i18next.t('common.permissionManage'),
      url: '/permission',
      icon: <SafetyOutlined />,
      id: Permission.PERMISSION_MANAGEMENT,
    },
  ];

  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname.startsWith(route.url || '###')) {
        setSelectedKey(route.key);
      }
    });
  }, [location.pathname, routes]);

  return (
    <>
      <div className={`${styles.sideNav} ${!isOpenSideNav ? styles.dp_none : ''}`}>
        <Link className={styles.logo} to="/dashboard">
          SELL ME
        </Link>
        <Menu
          selectedKeys={[selectedKey]}
          defaultOpenKeys={[]}
          mode="inline"
          inlineCollapsed={collapsed}
          style={{ transition: 'width 0.3s' }}
          className="width-100"
        >
          {routes.map((route) => {
            const isShow =
              route.id === '' || permissions.some((item: any) => item === route.id || item === route.subId);
            return isShow ? (
              <Menu.Item
                key={route.key}
                onClick={!pc ? () => setOpenSideNav(false) : () => {}}
                icon={route.icon}
                className="customMenu"
              >
                <Link to={route.url}>{route.text}</Link>
              </Menu.Item>
            ) : (
              <></>
            );
          })}
        </Menu>
      </div>
      {!pc && isOpenSideNav && <div className={styles.fade} onClick={() => setOpenSideNav(false)}></div>}
    </>
  );
}
