import { sendGet, sendPut, sendPost } from './axios';

export const getListTransactions = (payload: object | any) => {
  return sendGet('/cms/transactions/', payload);
};

export const getListPendingTransactions = (payload: object | any) => {
  return sendGet('/cms/transactions/list-transaction-pending', payload);
};

export const getTransactionById = (payload: object | any) => {
  return sendGet(`/cms/transactions/${payload.id}`);
};

export const getTransactionByUserId = (payload: object | any) => {
  return sendGet(`/cms/transactions/${payload.id}/by-user-id`);
};

export const getTransactionsOfAdmin = (payload: object | any) => {
  return sendGet(`/cms/transactions/list-transaction-by-user`, payload);
};

export const acceptTransaction = (payload: object | any) => {
  return sendPut(`/cms/transactions/${payload.id}/accept-transaction`);
};

export const refuseTransaction = (payload: object | any) => {
  const id = payload.id;
  delete payload.id;
  return sendPut(`/cms/transactions/${id}/refuse-transaction`, payload);
};

export const closeTransaction = (payload: object | any) => {
  return sendPut(`/cms/transactions/${payload.id}/close-transaction`);
};

export const finishTransaction = (payload: object | any) => {
  return sendPut(`/cms/transactions/${payload.id}/finish-transaction`);
};

export const receiveProduct = (payload: object | any) => {
  return sendPut(`/cms/transactions/${payload.id}/received-product`);
};

export const createOffer = (payload: object | any) => {
  return sendPost(`/cms/offers/`, payload);
};

export const updateSerialNumber = (payload: object | any) => {
  const id = payload.id;
  delete payload.id;
  return sendPut(`/cms/transactions/${id}/update-product-serial-number`, payload);
};

export const checkOnTransaction = () => {
  return sendGet(`/cms/transactions/transaction-appraising-by-user`);
};

export const payMoney = (payload: any) => {
  return sendPut(`/cms/offers/${payload.id}`);
};

export const getListExpiredTransactions = (payload: object | any) => {
  return sendGet('/cms/transactions/list-expired', payload);
};

export const getListTransactionUnpaid = (payload: object | any) => {
  return sendGet('/cms/transactions/list-transaction-unpaid', payload);
};
