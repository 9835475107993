/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import PageHeader from 'components/PageHeader';
import SideNav from 'components/SideNav';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { SideNavProvider } from 'context/SideNavContext';
import { getProfile } from 'api/profile';
import { getListRoles, getListPermissions } from 'api/permission';
import { checkOnTransaction } from 'api/transaction';
import { requestAccessToken } from 'api/authentication';
import { ScreenType } from 'constant/constant';
import oneSignal from 'config/oneSignal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import socket from 'socket';
import styles from './styles.module.scss';
import NotiBackToAssess from './NotiBacktoAssess';

const Assess = lazy(() => import('pages/Assess'));
const User = lazy(() => import('pages/User'));
const Member = lazy(() => import('pages/Member'));
const MemberProfile = lazy(() => import('pages/MemberProfile'));
const DashBoard = lazy(() => import('pages/Index'));
const Transaction = lazy(() => import('pages/Transaction'));
const Product = lazy(() => import('pages/Product'));
const MyProfile = lazy(() => import('pages/MyProfile'));
const ProductDetail = lazy(() => import('pages/ProductDetail'));
const Permission = lazy(() => import('pages/Permission'));
const TransactionDetail = lazy(() => import('pages/TransactionDetail'));
const Notify = lazy(() => import('pages/Notify'));
const Sale = lazy(() => import('pages/Sale'));
const Famous = lazy(() => import('pages/Famous'));
const FamousDetail = lazy(() => import('pages/FamousProfile'));
const TopPage = lazy(() => import('pages/TopPage'));
const Subcriber = lazy(() => import('pages/Subcriber'));
const Alert = lazy(() => import('pages/Alert'));

export default () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const pageHeaderRef = useRef<any>();
  const token = Cookies.get('token') || '';
  const route = history?.location?.pathname || '';
  const isAuthenticated = Boolean(token);
  const screenType =
    window.innerWidth > 768 ? ScreenType.PC : window.innerWidth < 376 ? ScreenType.SP : ScreenType.TABLET;
  const [isOpenSideNav, setOpenSideNav] = useState(screenType === ScreenType.PC);
  const [notiAssess, setNotiAssess] = useState({
    isShow: false,
    info: {},
  });
  //
  useQuery('profile', getProfile, {
    enabled: isAuthenticated,
    onSuccess: (data: any) => {
      oneSignal(onShowNoti, onClickNoti, data);
    },
  });
  useQuery('permission', getListPermissions, { enabled: isAuthenticated });
  useQuery('role', getListRoles, { enabled: isAuthenticated });
  useQuery('checkOnTransaction', checkOnTransaction, {
    enabled: isAuthenticated,
    onSuccess: (data: any) => {
      if (data?.data?.id) {
        setNotiAssess({
          isShow: !route.startsWith('/assess'),
          info: data?.data,
        });
      } else {
        setNotiAssess({
          isShow: false,
          info: {},
        });
      }
    },
  });

  useEffect(() => {
    socket.on('connect', async (data: any) => {
      socket.emit('authenticate', { token });
    });
    socket.on('unauthorized', (msg: any) => {
      let refreshToken = Cookies.get('refreshToken');
      if (!refreshToken) return;
      if (refreshToken) {
        requestNewToken();
      }
    });
    socket.on('disconnect', () => {});
  }, []);

  const requestNewToken = async () => {
    const refreshToken = Cookies.get('refreshToken');
    try {
      const res = await requestAccessToken({ refreshToken });
      const newToken = res?.data?.accessToken;
      if (!newToken) return;
      Cookies.set('token', newToken);
      socket
        .emit('authenticate', {
          token: newToken,
        })
        .on('authenticated', () => {});
    } catch (e) {}
  };

  useEffect(() => {
    queryClient.invalidateQueries('checkOnTransaction');
  }, [route]);

  // oneSignal;
  const onShowNoti = (payload: any) => {
    pageHeaderRef?.current?.insertNotify(payload);
  };

  const onClickNoti = (payload: any) => {
    if (history?.location?.pathname === '/transaction') {
      window.location.reload();
    } else {
      history.push('/transaction');
    }
  };

  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <SideNavProvider>
      <div className={styles.pageWrapper}>
        <NotiBackToAssess notiAssess={notiAssess} setNotiAssess={setNotiAssess} />
        <SideNav pc={screenType === ScreenType.PC} isOpenSideNav={isOpenSideNav} setOpenSideNav={setOpenSideNav} />
        <div className={styles.mainWrapper}>
          <PageHeader pc={screenType === ScreenType.PC} setOpenSideNav={setOpenSideNav} ref={pageHeaderRef} />
          <div className={styles.pageContent}>
            <Suspense fallback={null}>
              <Switch>
                <Route path="/sale" component={Sale} />
                <Route path="/dashboard" component={DashBoard} />
                <Route path="/assess/:id" component={Assess} />
                <Route path="/user" component={User} />
                <Route path="/member" component={Member} />
                <Route path="/member-profile/:memberId" component={MemberProfile} />
                <Route path="/transaction" component={Transaction} />
                <Route path="/product" component={Product} />
                <Route path="/profile" component={MyProfile} />
                <Route path="/product-detail/:id" component={ProductDetail} />
                <Route path="/permission" component={Permission} />
                <Route path="/notify" component={Notify} />
                <Route path="/famous" component={Famous} />
                <Route path="/subcriber" component={Subcriber} />
                <Route path="/famous-detail/:id" component={FamousDetail} />
                <Route path="/top" component={TopPage} />
                <Route path="/alert" component={Alert} />
                <Route exact path="/transaction-detail/:id" component={TransactionDetail} />
                <Redirect from="/" to="/sale" />
              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
    </SideNavProvider>
  );
};
