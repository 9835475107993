const DEV_ENV = {
  API_DOMAIN: 'https://sem-api.test.amelacorp.com',
  APP_ENV: 'dev',
  AGORA_APP_ID: '4e9d853e5a6c4d498e08a3b06d39e97c',
  AGORA_APP_CERTIFICATE: 'b9be40486c0b4a41a96ff724c482b696',
  ONESIGNAL_APP_ID: '13572a8a-6408-4e82-a431-e816224de2f7',
  ONESIGNAL_SAFARI_APP_ID: 'web.onesignal.auto.42873e37-42b9-4e5d-9423-af83e9e44ff4',
};
const STG_ENV = {
  API_DOMAIN: 'https://sem-api.stg.amelacorp.com',
  APP_ENV: 'stg',
  AGORA_APP_ID: '6d06f523c7864d1082de9dacdcc10ad4',
  AGORA_APP_CERTIFICATE: '527794e22aa74d119384aa7c9256dd1e',
  ONESIGNAL_APP_ID: 'e1b5cff5-783d-440c-92d8-59b7751c40c6',
  ONESIGNAL_SAFARI_APP_ID: 'web.onesignal.auto.165f55d2-2c2b-457d-b7b8-8b89270f4464',
};
const PROD_ENV = {
  API_DOMAIN: 'https://api.sellme.jp',
  APP_ENV: 'prod',
  AGORA_APP_ID: '6d06f523c7864d1082de9dacdcc10ad4',
  AGORA_APP_CERTIFICATE: '527794e22aa74d119384aa7c9256dd1e',
  ONESIGNAL_APP_ID: 'e1b5cff5-783d-440c-92d8-59b7751c40c6',
  ONESIGNAL_SAFARI_APP_ID: 'web.onesignal.auto.165f55d2-2c2b-457d-b7b8-8b89270f4464',
};

const env = process.env.REACT_APP_ENV;
const configs = env === 'prod' ? PROD_ENV : env === 'stg' ? STG_ENV : DEV_ENV;

export default configs;
