import { sendGet, sendPost, sendPut } from './axios';

export const getResource = () => sendGet('/cms/resource/all-resource');

export const getListSubCategory = () => sendGet('/cms/sub-categories/');

export const createSubCategory = (payload: any) => sendPost('/cms/sub-categories/', payload);

export const editSubCategories = (payload: any) => sendPut(`/cms/sub-categories/`, payload);

export const updateStatusSubCategory = (payload: object | any) => {
  const id = payload.id;
  delete payload.id;
  return sendPut(`/cms/sub-categories/${id}/change-status`, payload);
};

export const getListCategories = (payload: any) => sendGet('/cms/categories/', payload);

export const createCategory = (payload: any) => sendPost('/cms/categories/', payload);

export const editCategory = (payload: any) => {
  const id = payload.id;
  delete payload.id;
  return sendPut(`/cms/categories/${id}`, payload);
};

export const updateStatusCategory = (payload: object | any) => {
  const id = payload.id;
  delete payload.id;
  return sendPut(`/cms/categories/${id}/change-status`, payload);
};

export const getListBrands = (payload: any) => sendGet('/cms/brands/', payload);

export const createBrand = (payload: any) => sendPost('/cms/brands/', payload);

export const editBrand = (payload: any) => {
  const id = payload.id;
  delete payload.id;
  return sendPut(`/cms/brands/${id}`, payload);
};

export const updateStatusBrand = (payload: object | any) => {
  const id = payload.id;
  delete payload.id;
  return sendPut(`cms/brands/${id}/change-status`, payload);
};

export const getListKens = () => sendGet('/cms/kens/');

export const createKen = (payload: any) => sendPost('/cms/kens/', payload);

export const editKen = (payload: any) => sendPut(`/cms/kens/${payload.id}`, payload);

export const updateStatusKen = (payload: object | any) => {
  const id = payload.id;
  delete payload.id;
  return sendPut(`/cms/kens/${id}/change-status`, payload);
};

export const getListCities = () => sendGet('/cms/cities/');

export const createCity = (payload: any) => sendPost('/cms/cities/', payload);

export const editCity = (payload: any) => sendPut(`/cms/cities/${payload.id}`, payload);

export const updateStatusCity = (payload: object | any) => {
  const id = payload.id;
  delete payload.id;
  return sendPut(`/cms/cities/${id}/change-status`, payload);
};

export const getListConfig = () => sendGet('/cms/configs/');
