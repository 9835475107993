import i18n from 'i18next';

export const AWS_S3_DOMAIN = 'https://aml-sem-test-ads456.s3.amazonaws.com/';

export enum MemberIDType {
  DRIVING_LICENSE = 1,
  HEALTH_INSURANCE = 2,
  PASSPORT = 3,
  ID_CARD = 4,
  ARC = 5,
}

export enum AdminStauts {
  ACTIVE = 0,
  UNACTIVE = 1,
}

export enum MemberStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum TransactionStatus {
  PENDING = 1,
  SUCCESS = 2,
  FAILED = 3,
  SENT = 4,
  TIMEOUT = 5,
  DONE = 6,
  CANCEL = 7,
  APPRAISING = 8,
  SENT_DONE = 9,
  APPRAISE_AGAIN_FAILED = 10,
  RECEIVED = 11,
  RECEIVED_DONE = 12,
}

export enum RequestType {
  VIDEOCALL = 1,
  CHAT = 2,
}

export enum RedirectType {
  HOME = 1,
  TRANSACTION = 3,
  OFFER = 4,
  MEMBER_BANK = 5,
  MEMBER_ID_CARD = 6,
  SHIP = 7,
  MEMBER_ADDRESS = 8,
  UPDATE_PARCEL_CODE = 9,
  CONVERSATION = 10,
  VIDEO_CALL = 11,
}

export enum NotificationType {
  PERSON = 1,
  COMMON = 2,
}

export enum EventSocket {
  CONVERSATIONS = 'conversations',
  MESSAGES = 'messages',
  READ_MESSAGE = 'read_message',
  OFFERS = 'offers',
  CREATE_ROOM = 'create_room',
  JOIN_ROOM = 'join_room',
  LEAVE_ROOM = 'leave_room',
  VIDEO_CALL = 'video_call',
  CLOSE_TRANSACTION = 'close_transaction',
  REFUSE_TRANSACTION = 'refuse_transaction',
  START_TYPING = 'start_typing',
  END_TYPING = 'end_typing',
  TYPING = 'typing',
  OFF_TYPING = 'off_typing',
}

export const REQUEST_TYPE = [
  {
    id: RequestType.VIDEOCALL,
    name: i18n.t('common.videocall'),
  },
  {
    id: RequestType.CHAT,
    name: i18n.t('common.chat'),
  },
];

export const MEMBER_STATUS = [
  {
    id: MemberStatus.ACTIVE,
    name: i18n.t('common.memberActive'),
  },
  {
    id: MemberStatus.INACTIVE,
    name: i18n.t('common.memberInactive'),
  },
];

export const TRANSACTION_STATUS = [
  {
    id: TransactionStatus.PENDING,
    name: i18n.t('common.waitConfirm'),
  },
  {
    id: TransactionStatus.SUCCESS,
    name: i18n.t('common.transSuccess'),
  },
  {
    id: TransactionStatus.FAILED,
    name: i18n.t('common.transFailed'),
  },
  {
    id: TransactionStatus.SENT,
    name: i18n.t('common.transSent'),
  },
  {
    id: TransactionStatus.TIMEOUT,
    name: i18n.t('common.transTimeout'),
  },
  {
    id: TransactionStatus.DONE,
    name: i18n.t('common.transDone'),
  },
  {
    id: TransactionStatus.CANCEL,
    name: i18n.t('common.transCancel'),
  },
  {
    id: TransactionStatus.APPRAISING,
    name: i18n.t('common.appraising'),
  },
  {
    id: TransactionStatus.RECEIVED,
    name: i18n.t('common.transDone'),
  },
  {
    id: TransactionStatus.RECEIVED_DONE,
    name: i18n.t('common.transDone'),
  },
];

export const MEMBER_ID_TYPE = {
  DRIVING_LICENSE: {
    id: MemberIDType.DRIVING_LICENSE,
    text: '運転免許証',
  },
  HEALTH_INSURANCE: {
    id: MemberIDType.HEALTH_INSURANCE,
    text: '健康保険',
  },
  PASSPORT: {
    id: MemberIDType.PASSPORT,
    text: 'パスポート',
  },
  ID_CARD: {
    id: MemberIDType.ID_CARD,
    text: 'アイディーカード',
  },
  ARC: {
    id: MemberIDType.ARC,
    text: 'ARC',
  },
};

export enum MemberType {
  ADMIN = 2,
  MEMBER = 1,
}

export enum ScreenType {
  SP = 'SP',
  TABLET = 'TABLET',
  PC = 'PC',
}

export enum ProductStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum Role {
  SUPER_ADMIN = 1,
  ADMIN = 2,
}

export enum UserStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum FamousStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum OfferStatus {
  PENDING = 1,
  FAILED = 2,
  SUCCESS = 3,
  PAID = 4,
}

export enum MessageType {
  NOTI = 3,
  MESSAGE = 1,
}

export enum Permission {
  RESOURCE_MANAGEMENT = 1,
  LANGUAGE_MANAGEMENT = 2,
  USER_MANAGEMENT = 3,
  MEMBER_MANAGEMENT = 4,
  PRODUCT_MANAGEMENT = 5,
  PERMISSION_MANAGEMENT = 6,
  FAMOUS_PEOPLE_MANAGEMENT = 7,
  TRANSACTION_MANAGEMENT = 8,
  APPRAISE_MANAGEMENT = 9,
  PUSH_NOTIFICATION_MANAGEMENT = 10,
}

export enum RegistrationCodeType {
  PERSONAL = 1,
  FAMOUS_PEOPLE = 2,
}

export enum NotificationType {}

export const REGISTRATION_CODE_TYPE = [
  {
    id: RegistrationCodeType.PERSONAL,
    name: i18n.t('common.bySelf'),
  },
  {
    id: RegistrationCodeType.FAMOUS_PEOPLE,
    name: i18n.t('common.byFamous'),
  },
];

export const OFFER_STATUS = [
  {
    id: OfferStatus.FAILED,
    name: i18n.t('common.refuseOffer'),
  },
  {
    id: OfferStatus.SUCCESS,
    name: i18n.t('common.notPaid'),
  },
  {
    id: OfferStatus.PENDING,
    name: i18n.t('common.pendingOffer'),
  },
  {
    id: OfferStatus.PAID,
    name: i18n.t('common.paid'),
  },
];

export const PAGE_SIZE_OPTIONS = ['5', '10', '20', '50', '100'];

export const Famous_STATUS = [
  {
    id: FamousStatus.ACTIVE,
    name: 'active',
  },
  {
    id: FamousStatus.INACTIVE,
    name: 'inactive',
  },
];

export const USER_STATUS = [
  {
    id: UserStatus.ACTIVE,
    name: 'active',
  },
  {
    id: UserStatus.INACTIVE,
    name: 'inactive',
  },
];

export const PRODUCT_STATUS = [
  {
    id: ProductStatus.ACTIVE,
    name: 'アクティブ',
  },
  {
    id: ProductStatus.INACTIVE,
    name: 'インアクティブ',
  },
];

export const ROLE = [
  {
    id: Role.SUPER_ADMIN,
    name: 'SUPER ADMIN',
    value: 'SUPER ADMIN',
  },
  {
    id: Role.ADMIN,
    name: 'ADMIN',
    value: 'ADMIN',
  },
];

export const WEEK_DAY = [
  {
    id: 0,
    name: '日',
    value: 'sunday',
  },
  {
    id: 1,
    name: '月',
    value: 'monday',
  },
  {
    id: 2,
    name: '火',
    value: 'tuesday',
  },
  {
    id: 3,
    name: '水',
    value: 'wednesday',
  },
  {
    id: 4,
    name: '木',
    value: 'thusday',
  },
  {
    id: 5,
    name: '金',
    value: 'friday',
  },
  {
    id: 6,
    name: '土',
    value: 'sartuday',
  },
];

export const checkURL = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
);
