/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import Cookies from 'js-cookie';
import avatarImg from 'assets/icons/profile-user.png';
import { useHistory, Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { Menu, Dropdown } from 'antd';
import { MenuUnfoldOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import bell from 'assets/icons/bell.png';
import { getListNotification, readNoti } from 'api/notify';

interface IProps {
  pc: Boolean;
  setOpenSideNav: Function;
}

const countUnread = (list: Array<any> = []) => {
  let result = 0;
  list.map((item) => {
    if (item.isRead === 0) result += 1;
    return true;
  });
  return result;
};
const PageHeader = forwardRef((props: IProps, ref) => {
  const { pc, setOpenSideNav } = props;
  const history = useHistory();
  const queryClient = useQueryClient();
  const data = queryClient?.getQueryData('profile') as any;
  const profile = data?.data;
  const [notifications, setNotifications] = useState<any>([]);
  const [filter, setFilter] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  useEffect(() => {
    getNotifications();
  }, [filter]);

  useImperativeHandle(ref, () => {
    return {
      insertNotify: insertNotify,
    };
  });

  const handleLogout = async () => {
    // try {
    //   await logout();
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    history.push('/login');
    // } catch (error) {
    //   //
    // }
  };

  const insertNotify = (payload: any) => {
    let notiClone = [...notifications];
    const newNoti = {
      redirectType: payload?.data?.redirectType,
      redirectId: payload?.data?.redirectId,
      isRead: 0,
      ...payload,
    };
    notiClone.unshift(newNoti);
    setNotifications(notiClone);
  };

  const getNotifications = async () => {
    try {
      const res = await getListNotification(filter);
      const notiClone = [...notifications, ...res?.data];
      setNotifications(notiClone || []);
    } catch (error) {}
  };

  const onClickNoti = async (item: any) => {
    if (item.redirectId) {
      const route = `/transaction-detail/${item.redirectId}`;
      if (history?.location?.pathname !== route) {
        history.push(route);
      } else {
        window.location.reload();
      }
    }
    if (item.id) {
      let notificationsClone = [...notifications];
      const index = notificationsClone.findIndex((el) => el.id === item.id);
      if (index !== -1) notificationsClone[index].isRead = 1;
      setNotifications(notificationsClone);
      try {
        await readNoti({ id: item.id });
      } catch (error) {}
    }
  };

  const handleScroll = (event: any) => {
    const list = document.getElementById('list-noti') || document.createElement('div');
    const scrollHeight = list.scrollHeight - list.clientHeight;
    if (event.target.scrollTop >= scrollHeight - 5) {
      setFilter({ ...filter, pageIndex: filter.pageIndex + 1 });
    }
  };

  const dropdownNoti = () => {
    return (
      <Menu className={styles.listNoti} id="list-noti" onScroll={handleScroll}>
        {notifications.map((item: any) => (
          <Menu.Item
            key={item.id}
            className={item.isRead ? styles.noti : styles.noti_read}
            id={item.id}
            onClick={() => onClickNoti(item)}
          >
            {item?.content.replace('｛transactionId｝', item?.redirectId + ' ')}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const menu = (
    <Menu className="w-150">
      <Menu.Item key="1">
        <Link to="/profile">
          <UserOutlined />
          <span className="ml-5"> プロフィール</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="3" onClick={handleLogout}>
        <LogoutOutlined />
        <span className="ml-5">ログアウト</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.title}>
        {!pc && <MenuUnfoldOutlined onClick={() => setOpenSideNav(true)} />}
        <span className="ml-5"></span>
      </div>
      <div className={styles.menuWrapper}>
        <div className={`${styles.menuItem} d-flex al-center`}>
          <Dropdown overlay={dropdownNoti} trigger={['click']} className="ml-10 mr-10">
            <div className="relative">
              <img src={bell} alt="" className="table_icon" />
              {countUnread(notifications) > 0 ? <div className={styles.count} /> : <div />}
            </div>
          </Dropdown>
          <Dropdown overlay={menu} trigger={['click']} className="ml-10 mr-10">
            <div>
              <img className={styles.avatar} src={profile?.avatar || avatarImg} alt="" />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
});

export default PageHeader;
