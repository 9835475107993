import { sendGet, sendPut, sendPost } from './axios';

export const getListPermissions = () => {
  return sendGet('/cms/permissions/');
};

export const getPermissionByRoleId = (payload: any) => {
  return sendGet(`/cms/permissions/rolePermission/${payload.id}`);
};

export const getListRoles = () => {
  return sendGet('/cms/permissions/role');
};

export const hideRole = (payload: object | any) => {
  return sendPut(`/cms/permissions/hidden-role/${payload.id}`);
};

export const createRole = (payload: object | any) => {
  return sendPost(`/cms/permissions/role`, payload);
};

export const editRole = (payload: object | any) => {
  const id = payload.id;
  delete payload.id;
  return sendPut(`/cms/permissions/role/${id}`, payload);
};

export const updatePermissionRole = (payload: object | any) => {
  const id = payload.id;
  delete payload.id;
  return sendPut(`/cms/permissions/rolePermission/${id}`, payload);
};

export const getPermissionById = (payload: any) => {
  return sendGet(`/cms/permissions/user/${payload.id}`);
};
